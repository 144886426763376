/* You can add global styles to this file, and also import other style files */
.cursor-pointer {
  cursor: pointer;
}

.cursor-croshair {
  cursor: crosshair;
}

.cursor-eresize {
  cursor: e-resize;
}

.cursor-move {
  cursor: move;
}
